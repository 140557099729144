import "./index.scss"
import * as React        from "react"
import {connect}         from "react-redux"
import {ThunkDispatch}   from "redux-thunk"
import Cookies           from "js-cookie"
import {State}           from "../../redux/reducers/"
import {Action}          from "../../model/constant/actions"
import {
  login,
  rememberMeLogin
}                        from "../../actions/Auth/authentication"
// import TextField         from "@material-ui/core/TextField"

import Title from '../../components/Title/Title'
import ContactItemInput from '../AccountActivation/components/form/contact_item_input/ContactItemInput'

interface LoginOwnProps{

}

const mapStateToProps = (state:State, ownProps:LoginOwnProps)=>{
  return {
    error : state.Login.Error,
    loggingIn : state.Login.LoggingIn
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:LoginOwnProps)=>{
  return {
    login : (...args:Parameters<typeof login>)=>dispatch(login(...args)),
    rememberMeLogin : (...args:Parameters<typeof rememberMeLogin>)=>dispatch(rememberMeLogin(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:LoginOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoginProps = ReturnType<typeof mergeProps>
const Login = (props:LoginProps) => {
  const sendRef = React.createRef<HTMLButtonElement>()
  const [email, setEmail] = React.useState<string>("")
  const [pwd, setPwd] = React.useState<string>("")
  const [remember, setRemember] = React.useState<boolean>(false)

  if(props.loggingIn){return <div className='Login'>LOGGING IN</div>}
  if(Cookies.get("email") && Cookies.get("UID")){
    props.rememberMeLogin(Cookies.get("email") || "", Cookies.get("UID") || "")
  }

  const onEnterPress = (e:React.KeyboardEvent<HTMLDivElement>)=>{
    if(e.key === "Enter" && sendRef && sendRef.current){
      sendRef.current.click()
    }
  }

  return (
    <div className="Login">
      <Title h1title={'Scraping Desk'} />
      <div className="credentials">
        <ContactItemInput labelText="Email" dataType="email" inputName="email" handleOnChange={(e)=>setEmail(e.target.value)} />
        <ContactItemInput labelText="Password" dataType="password" inputName="password" handleOnChange={(e)=>setPwd(e.target.value)} handleOnKeyDown={onEnterPress} />
      </div>
      <div className="checkboxDiv">
        <div>
          <label htmlFor="rememberMeID">Remember Me:</label>
          <input
          id="rememberMeID"
          className="switch importedButton"
          type="checkbox"
          checked={remember}
          onChange={(e)=>setRemember(e.target.checked)}
          />
        </div>
        <button className={`btn btn_blue`} ref={sendRef} onClick={()=>props.login(email, pwd, remember)}>Login</button>
      </div>

      {props.error && props.error.length &&
        <div className="errorDiv">{props.error}</div>
      }

    </div>
  )
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(Login)
