import {createReducer} from "../../../utils/Reducers"
import ActionTypes     from "../../../model/constant/actions"
import {v4 as uuid}    from "uuid"
import moment          from "moment"
import Note            from "../../../model/Note"

interface NotesByContext {
  [contextId:string] : Note[]
}

const ContextNotes = createReducer<NotesByContext>({}, {
  [ActionTypes.ADD_CONTEXT_NOTE] : (state, action)=>({
    ...state,
    [action.payload.contextId] : [
      {id: uuid(), text: action.payload.text, when: moment(), who: action.payload.who},
      ...(state[action.payload.contextId]||[]),
    ]
  }),
  [ActionTypes.REMOVE_CONTEXT_NOTE] : (state, action)=>{
    if(!state[action.payload.contextId]){return state}
    return {
      ...state,
      [action.payload.contextId] : state[action.payload.contextId].filter(x=>x.id !== action.payload.noteId)
    }
  },
})

export default ContextNotes
