import "./ScrapingJobResult.scss"
import * as React                       from "react"
import {useSelector}                    from "react-redux"
import {JobGeneric, JobType, JobStatus} from "../../../model/Job"
import ScrapingContext                  from "../../../model/ScrapingContext"
import {State}                          from "../../../redux/reducers/"
import Dialog                           from "@material-ui/core/Dialog"
import AppBar                           from "@material-ui/core/AppBar"
import Toolbar                          from "@material-ui/core/Toolbar"
import IconButton                       from "@material-ui/core/IconButton"
import Typography                       from "@material-ui/core/Typography"
import Slide                            from "@material-ui/core/Slide"
import { TransitionProps }              from "@material-ui/core/transitions"
import CloseIcon                        from "@material-ui/icons/Close"

interface ScrapingJobResultOwnProps{
  context : ScrapingContext
  job     : JobGeneric<JobType.ContextScraping>
}

const Transition = React.forwardRef((props:TransitionProps & { children?: React.ReactElement }, ref)=> {
  return <Slide direction="up" ref={ref} {...props} />;
})

const ScrapingJobResult = (props:ScrapingJobResultOwnProps) => {
  const [showResult, setShowResult] = React.useState<boolean>(false)
  const retrievingContextsInventory = useSelector((state:State)=>state.Contexts.Retrieving.ContextInventory)
  const contextsInventory = useSelector((state:State)=>state.Contexts.Inventory)
  const openResult = ()=>setShowResult(true)
  const hideResult = ()=>setShowResult(false)
  if(props.job.status !== JobStatus.FINISHED || !props.job.result){return <div>Scraping job must have a result and its status must be FINISHED for this component</div>}
  if(retrievingContextsInventory[props.context.ContextID] || !contextsInventory[props.context.ContextID]){return <div>Loading new inventory</div>}
  return (
    <div className="ScrapingJobResult_Parent">
      <button onClick={openResult}>
        See results
      </button>
      <Dialog fullScreen open={showResult} onClose={hideResult} TransitionComponent={Transition}>
        <AppBar style={{position: "relative", padding: "15px", alignItems: "normal", backgroundColor: "#232323"}}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={hideResult} aria-label="close" style={{backgroundColor: "#181818", color:"#eee"}}>
              <CloseIcon/>
            </IconButton>
            <Typography variant="h6" style={{flex: 1, color: "#eee"}}>
              {props.context.AccountID} {props.context.ContextID} {props.context.VehicleState} {props.context.Lang}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="ScrapingJobResult_ResultsContent">
          <div>Inventory Count: <span className="ScrapingJobResult_ResultsContent__Count">{contextsInventory[props.context.ContextID].length}</span> | Inventory Dump: </div> 
          <hr/>
          <textarea className="ScrapingJobResult_ResultsContent__textarea">{JSON.stringify(contextsInventory[props.context.ContextID], undefined, 4)}</textarea>
        </div>
      </Dialog>
    </div>
  )
}

export default ScrapingJobResult
