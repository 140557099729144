import {createReducer} from "../../../utils/Reducers"
import ActionTypes     from "../../../model/constant/actions"

interface HiddenByContext {
  [contextId:string] : boolean
}

const HiddenContext = createReducer<HiddenByContext>({}, {
  [ActionTypes.SET_CONTEXT_HIDDEN_STATE] : (state, action)=>({
    ...state,
    [action.payload.contextId] : action.payload.hidden
  })
})

export default HiddenContext
