import "./Context.scss"
import * as React                from "react"
import ScrapingContext           from "../../model/ScrapingContext"
import Job, {
  JobStatus,
}                                from "../../model/Job"
import Card                      from "@material-ui/core/Card"
import Tooltip                   from "@material-ui/core/Tooltip"

import ContextJob                from "./ContextJob"
import ContextActions            from "./ContextActions"
import ContextHiddenButton       from "./ContextHiddenButton"
import ContextNotes              from "./ContextNotes"

interface ContextOwnProps{
  context : ScrapingContext
  clientName ?: string
  job ?: Job
  providers : string[]
  hidden ?: boolean
}

const getAppropriateStatusClass = (context:ScrapingContext, job?:Job):string => {
  if(!job){return ""}
  if(job.status === JobStatus.RUNNING){return "Running"}
  if(job.status === JobStatus.FINISHED){return "Finished"}
  if(job.status === JobStatus.FAILED){return "Failed"}
  return ""
}

const Context = (props:ContextOwnProps) => {
  const clientName = props.clientName || "Unknown"
  const {DayCountSinceNoInventory, ContextID, AccountID, BaseURL, VehicleState, Lang} = props.context
  const statusClass = getAppropriateStatusClass(props.context, props.job)
  return (
    <div className={"Context "+statusClass}>
      <div className="TopLeftColumn">
        <Tooltip title={<span className="tooltip_span">Day count since no inventory</span>}>
          <Card className="DayCount">{DayCountSinceNoInventory}</Card>
        </Tooltip>
        <ContextHiddenButton
          contextId={props.context.ContextID}
          hidden={props.hidden}
        />
      </div>
      <div className="Description">
        <div className="FirstRow">
          <div>{clientName} <code>{AccountID}</code></div>
          <div className="ContextDetails">
            <label>Context:</label>
            <span><code>{ContextID}</code></span>
            <span><code>{VehicleState}</code></span>
            <span><code>{Lang}</code></span>
          </div>
        </div>
        <div className="SecondRow">
          <a href={BaseURL} target="_blank" rel="noopener noreferrer">{BaseURL}</a>
          <ContextNotes context={props.context}/>
        </div>
      </div>
      <div className="Actions">
        {props.job
          ? <ContextJob context={props.context} job={props.job}/>
          : <ContextActions context={props.context} providers={props.providers}/>
        }
      </div>
    </div>
  )
}

export default Context
