import {
  StandardThunk,
  createAddContextScrapingJob,
  createPutContextScrapingJob,
  createPollingContextScrapingJob
}                          from "../creators"
import axios, {
  AxiosError,
}                          from "axios"
import startEndpoint, {
  Success as StartSuccess,
  Failure as StartFailure
}                          from "../../model/http/Scraping/RunContextScraping"
import pollEndpoint, {
  Success as PollSuccess,
  Failure as PollFailure,
  HorizonJobStatus
}                          from "../../model/http/Scraping/HorizonJob"
import {JobStatus}         from "../../model/Job"
import {getContextInventory} from "./inventory"


export const startContextScrapingJob = (contextId:number):StandardThunk => (dispatch, getState) => {
  axios.post<StartSuccess>(startEndpoint(contextId), {}, {
    headers : {Authorization: "Bearer "+getState().Login.User.token}
  })
  .then((response)=>{
    dispatch(createAddContextScrapingJob({
      contextId,
      jobId : response.data.jobId,
      jobDetails : {} as any
    }))
  })
  .catch((response:AxiosError<StartFailure>)=>{
    console.log(response)
  })
}
export const pollContextScrapingJob = (contextId:number, jobId:string):StandardThunk => (dispatch, getState) => {
  dispatch(createPollingContextScrapingJob({contextId, polling: true}))
  const state = getState()
  new Promise(r=>r())
   axios.get<PollSuccess>(pollEndpoint(jobId), {
     headers : {Authorization: "Bearer "+state.Login.User.token}
   })
  .then((response)=>{
    if(response.data && response.data.status){
      if(response.data.status === HorizonJobStatus.COMPLETED){
        dispatch(createPutContextScrapingJob({contextId, job: {status: JobStatus.FINISHED, result: response.data}}))
        const currentContext = state.Contexts.WithoutVehicles.find(x=>x.ContextID === contextId)
        if(currentContext){dispatch(getContextInventory(currentContext))}
      }
      if(response.data.status === HorizonJobStatus.FAILED){
        dispatch(createPutContextScrapingJob({contextId, job: {status: JobStatus.FAILED}}))
      }
    }
  })
  .catch((response:AxiosError<PollFailure>)=>{
    console.log(response)
  })
  .then(()=>{
    dispatch(createPollingContextScrapingJob({contextId, polling: false}))
  })
}
