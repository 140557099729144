import {createReducer} from "../../../utils/Reducers"
import ActionTypes     from "../../../model/constant/actions"
import moment          from "moment"
import Job, {
  JobStatus,
  JobType
}             from "../../../model/Job"

interface JobsState{
  [contextId:string] : Job
}

const Jobs = createReducer<JobsState>({}, {
  [ActionTypes.ADD_CONTEXT_ANALYSIS_JOB] : (state, action)=>{
    return {
      ...state,
      [action.payload.contextId] : {
        id      : action.payload.jobId,
        type    : JobType.ContextAnalysis,
        details : action.payload.jobDetails,
        status  : JobStatus.RUNNING,
        started : moment(),
        polling : false
      }
    }
  },
  [ActionTypes.POLLING_CONTEXT_ANALYSIS_JOB] : (state, action)=>{
    if(!state[action.payload.contextId]){return state}
    return {
      ...state,
      [action.payload.contextId] : {
        ...state[action.payload.contextId],
        polling : action.payload.polling
      }
    }
  },
  [ActionTypes.PUT_CONTEXT_ANALYSIS_JOB] : (state, action)=>{ // TODO split this action for updating different status... typing is fucked right now
    if(!state[action.payload.contextId]){return state}
    return {
      ...state,
      [action.payload.contextId] : {
        ...state[action.payload.contextId],
       ...(action.payload.job as any)
      }
    }
  },
  [ActionTypes.ADD_CONTEXT_SCRAPING_JOB] : (state, action)=>{
    return {
      ...state,
      [action.payload.contextId] : {
        id      : action.payload.jobId,
        type    : JobType.ContextScraping,
        details : action.payload.jobDetails,
        status  : JobStatus.RUNNING,
        started : moment(),
        polling : false
      }
    }
  },
  [ActionTypes.POLLING_CONTEXT_SCRAPING_JOB] : (state, action)=>{
    if(!state[action.payload.contextId]){return state}
    return {
      ...state,
      [action.payload.contextId] : {
        ...state[action.payload.contextId],
        polling : action.payload.polling
      }
    }
  },
  [ActionTypes.PUT_CONTEXT_SCRAPING_JOB] : (state, action)=>{ // TODO split this action for updating different status... typing is fucked right now
    if(!state[action.payload.contextId]){return state}
    return {
      ...state,
      [action.payload.contextId] : {
        ...state[action.payload.contextId],
       ...(action.payload.job as any)
      }
    }
  },
  [ActionTypes.FORGET_CONTEXT_JOB] : (state, action)=>{
    if(!state[action.payload.contextId]){return state}
      const newState = {...state}
      delete newState[action.payload.contextId]
      return newState
  }
})

export default Jobs
