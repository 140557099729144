import React from 'react'
import './Title.scss'

interface TitleProps {
  h1title : string
  additionalClasses ?: string
}

const Title = (props: TitleProps) => {
  return (
    <div className={`display display1 ${props.additionalClasses || ''}`}>
      <div className="display_container">
        <h4 className="display_title">&nbsp;{props.h1title}&nbsp;</h4>
      </div>
    </div>
  )
}

export default Title
