import {combineReducers} from "redux"
import WithoutVehicles           from "./WithoutVehicles"
import ContextInventory          from "./ContextInventory"

const Retrieving = combineReducers({
  WithoutVehicles,
  ContextInventory,
})

export default Retrieving
