import {createReducer} from "../../../utils/Reducers"
import ActionTypes     from "../../../model/constant/actions"
import Vehicle         from "../../../model/Vehicle"

interface InventoryByContext {
  [contextId:string] : Vehicle[]
}

const ContextInventory = createReducer<InventoryByContext>({}, {
  [ActionTypes.RETRIEVE_CONTEXT_INVENTORY] : (state, action)=>({
    ...state,
    [action.payload.contextId] : action.payload.inventory
  })
})

export default ContextInventory
