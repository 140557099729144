import {
  StandardThunk,
  createRetrieveContextsWithoutVehicles,
  createRetrievingContextsWithoutVehicles,
  createFailedLogin,
  createLogout
}                          from "../creators"
import axios               from "axios"
import endpoint, {
  Success
}                          from "../../model/http/Scraping/GetActiveContextsWithoutVehicles"


export const getContextsWithoutVehicles = ():StandardThunk => (dispatch, getState) => {
  dispatch(createRetrievingContextsWithoutVehicles(true))
  axios.get<Success>(endpoint, {
    headers : {Authorization: "Bearer "+getState().Login.User.token}
  })
  .then((response)=>{
    dispatch(createRetrieveContextsWithoutVehicles(response.data))
  })
  .catch((error)=>{
    console.log(error)
    if (error.response && [401,403].includes(error.response.status)) {
      dispatch(createFailedLogin("Token expired"))
      dispatch(createLogout({}))
    } else {
      console.log(error)
    }
  })
  .then(()=>{
    dispatch(createRetrievingContextsWithoutVehicles(false))
  })
}
