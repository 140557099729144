import baseUrl from "./"

export default (contextId:number, provider:string) => baseUrl+"SetUpScraping/"+contextId+"/RunSetUpScraping/"+provider

export interface Success {
  message : string
  jobId   : string
}

export interface Failure{

}
