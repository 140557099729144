import baseUrl  from "./"
import Language from "../../constant/Language"
import Vehicle  from "../../Vehicle"

export default (accountId:number, lang:Language) => baseUrl+"Account/"+accountId+"/Inventory/Lang/"+lang+"/LimitTo/current"

export interface Success{
  data : {
    lang : Language
    inventory : Vehicle[]
  }
}

export interface Failure {

}
