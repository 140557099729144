import "./ContextJob.css"
import * as React               from "react"
import moment                   from "moment"
import {useDispatch}            from "react-redux"
import ScrapingContext          from "../../model/ScrapingContext"
import {createForgetContextJob} from "../../actions/creators"
import AnalysisJobResult        from "./AnalysisJobResult/AnalysisJobResult"
import ScrapingJobResult        from "./ScrapingJobResult/ScrapingJobResult"
import Job, {
  JobStatus,
  JobType
}                               from "../../model/Job"
import IconButton               from "@material-ui/core/IconButton"
import CancelIcon               from "@material-ui/icons/Cancel"

interface ContextJobOwnProps{
  context : ScrapingContext
  job     : Job
}

const ContextJob = (props:ContextJobOwnProps) => {
  const dispatch = useDispatch()
  return (
    <div className="ContextJob">
      <div className="Status">
        {props.job.polling
        ? "Polling"
        : props.job.status !== JobStatus.FINISHED
          ? props.job.status
          : props.job.type === JobType.ContextAnalysis
            ? <AnalysisJobResult job={props.job} context={props.context}/>
            : props.job.type === JobType.ContextScraping
              ?  <ScrapingJobResult job={props.job} context={props.context}/>
              : "FINISHED"
        }
        <IconButton
          className="ForgetButton"
          onClick={()=>dispatch(createForgetContextJob({contextId: props.context.ContextID}))}
          style={{background:"none", color: "#f9c412"}}
         >
          <CancelIcon/>
        </IconButton>
      </div>
      <div className="Details">
        Started: ~{moment().diff(props.job.started, "seconds")} seconds ago
        {props.job.type === JobType.ContextAnalysis
        ? <div>Provider: {props.job.details.provider}</div>
        : <div></div>
        }
      </div>
    </div>
  )
}

export default ContextJob
