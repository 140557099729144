import "./MessageList.css"
import * as React                           from "react"
import moment                               from "moment"
import Note                                 from "../../model/Note"
import Message                              from "./Message"

interface MessageListOwnProps{
  messages : Note[]
  onMessageDelete : (messageId:string)=>void
  // The number of days that a message can be from before it's considered expired. 0 = Yesterday+ is expired
  dayCountBeforeMessageExpired ?: number
  expiredMessageTooltip ?: string
}

const separateExpiredMessages = (messages:Note[], dayCountBeforeMessageExpired?:number):{fresh: Note[], expired: Note[]} => {
  if(dayCountBeforeMessageExpired === undefined || dayCountBeforeMessageExpired < 0 || messages.length === 0){
    return {fresh: [...messages], expired: []}
  }
  const fresh:Note[] = []
  const expired:Note[] = []
  const cutoff = moment().subtract(dayCountBeforeMessageExpired, "day")
  for(const message of messages){
    (message.when.isBefore(cutoff, "days") ? expired : fresh).push(message)
  }
  return {fresh, expired}
}

const MessageList = (props:MessageListOwnProps) => {
  const [showExpired, setShowExpired] = React.useState<boolean>(false)
  if(props.messages.length === 0){
    return <div className="MessageList Empty">Nothing to show</div>
  }
  const {fresh, expired} = separateExpiredMessages(props.messages, props.dayCountBeforeMessageExpired)
  fresh.sort((a, b)=> b.when.valueOf() - a.when.valueOf())
  expired.sort((a, b)=> b.when.valueOf() - a.when.valueOf())
  return (
    <div className="MessageList">
      {expired.length > 0 &&
        <div>
          <input type="checkbox" checked={showExpired} onChange={e=>setShowExpired(e.target.checked)}/>
          <span onClick={e=>setShowExpired(prev=>!prev)} style={{cursor: "pointer", fontSize: "12px"}}>Show expired ({expired.length})</span>
        </div>
      }
      {fresh.map(x=>
        <Message key={x.id} message={x} onMessageDelete={props.onMessageDelete}/>
      )}
      {showExpired && expired.map(x=>
        <Message
          key={x.id}
          message={x}
          onMessageDelete={props.onMessageDelete}
          expiredMessageTooltip={props.expiredMessageTooltip}
          expired
        />
      )}
    </div>
  )
}

export default MessageList
