import {createReducer} from "../../../utils/Reducers"
import ActionTypes     from "../../../model/constant/actions"
import Context       from "../../../model/ScrapingContext"

const WithoutVehicles = createReducer<Context[]>(null, {
  [ActionTypes.RETRIEVE_CONTEXTS_WITHOUT_VEHICLES] : (state, action)=>action.payload,
  [ActionTypes.CLEAR_CONTEXTS_WITHOUT_VEHICLES] : (state, action)=>null
})

export default WithoutVehicles
