import './index.css'
import React                   from 'react'
import ReactDOM                from 'react-dom'
import {Provider}              from "react-redux"
import buildStore, {history}   from "./redux/"
import {ConnectedRouter}       from "connected-react-router"
import App                     from './App'

const store = buildStore()

const Root = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App/>
    </ConnectedRouter>
  </Provider>
)

// root div doesn't exist in test env
if(document.getElementById("root")){
  ReactDOM.render(<Root/>, document.getElementById("root"))
}

export default Root
