import "./ContextActions.scss"
import * as React                from "react"
import {useDispatch}             from "react-redux"
import {startContextAnalysisJob} from "../../actions/Scraping/analysis"
import {startContextScrapingJob} from "../../actions/Scraping/scraping"
import ScrapingContext           from "../../model/ScrapingContext"
import ArrowDropDownIcon         from "@material-ui/icons/ArrowDropDown"
import TextField                 from "@material-ui/core/TextField"
import IconButton                from "@material-ui/core/IconButton"
import Menu                from "@material-ui/core/Menu"
import MenuItem                from "@material-ui/core/MenuItem"
import Autocomplete              from "@material-ui/lab/Autocomplete"


interface ContextActionsOwnProps{
  context : ScrapingContext
  providers : string[]
}

interface ProviderSelectOption{
  value : string
  title : string
}

const ContextActions = (props:ContextActionsOwnProps) => {
  const defaultAnalysisProvider = {
    value: props.context.Provider || "None",
    title: (props.context.Provider || "None")+"*"
  }
  const [analysisProvider, setAnalysisProvider] = React.useState<ProviderSelectOption>(defaultAnalysisProvider)
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = React.useState<null|HTMLElement>(null)
  const closeMoreActionsMenu = ()=>setMoreActionsAnchorEl(null)
  const dispatch = useDispatch()
  const onAnalysisStart = ()=>{
    dispatch(startContextAnalysisJob(props.context.ContextID, analysisProvider.value))
  }
  return (
    <div className="ContextActions">
      <IconButton
        className="MoreActionsButton"
        disableRipple
        disableFocusRipple
        onClick={(e)=>setMoreActionsAnchorEl(e.currentTarget)}
      >
        <ArrowDropDownIcon/>
      </IconButton>
      <Menu
        anchorEl={moreActionsAnchorEl}
        open={Boolean(moreActionsAnchorEl)}
        onClose={closeMoreActionsMenu}
      >
        <MenuItem onClick={()=>{dispatch(startContextScrapingJob(props.context.ContextID));closeMoreActionsMenu()}}>Run scraping</MenuItem>
      </Menu>
      <button
        className="StartAnalysisButton"
        onClick={onAnalysisStart}
        disabled={analysisProvider.value === "None"}
      >
        Analysis
      </button>
      <div className="context_combobox">
      <Autocomplete
        options={[
          {value: "None", title: "None"},
          ...props.providers.map(x=>({value:x, title:x!==props.context.Provider ? x : x+"*"}))
        ]}
        value={analysisProvider}
        autoSelect
        onChange={(e, newValue)=>{
          setAnalysisProvider((newValue || defaultAnalysisProvider))
        }}
        disableClearable
        getOptionLabel={(option:ProviderSelectOption)=>option.title}
        getOptionSelected={(option:ProviderSelectOption, value:ProviderSelectOption)=>option.value === value.value}
        renderOption={(option: ProviderSelectOption) => <span className="context_combobox">{option.title}</span>}
        renderInput={(params)=>
          <TextField
            {...params}
            label="provider"
            variant="outlined"
            inputProps={{...params.inputProps, style: {border: "none", color: "#eee"}}}
          />
        }
        forcePopupIcon={false}
        style={{width: "100%"}}
      />
      </div>
    </div>
  )
}

export default ContextActions
