import {
  StandardThunk,
  createRetrievingAccounts,
  createRetrieveAccounts,
}                                         from "../creators"
import baseUrl,{Success}                  from "../../model/http/Auth2/Accounts"
import axios                              from "axios"

export const getAccounts = ():StandardThunk => (dispatch, getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingAccounts(true))
  axios.get<Success>(baseUrl,{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(createRetrieveAccounts(response.data))
  })
  .catch((error)=>{
    console.log(error)
  })
  .then(()=>{
    dispatch(createRetrievingAccounts(false))
  })

}
