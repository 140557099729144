import {createReducer} from "../../../utils/Reducers"
import ActionTypes     from "../../../model/constant/actions"
import Account       from "../../../model/Account"

interface ReducerState {
  [accountId:number] : Account
}

const AccountsById = createReducer<ReducerState>(null, {
  [ActionTypes.RETRIEVE_ACCOUNTS] : (state, action)=>action.payload.reduce((byId:ReducerState, account)=>{
    byId[account.id] = account
    return byId
  },{}),
})

export default AccountsById
