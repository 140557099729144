import axios                 from 'axios'
import { InputFormDataType } from '../../../model/accountCreator/form_types'

type RawAddressObject = {
  street_number : string
  street_name   : string
  city          : string
  province      : string
  country       : string
  zipCode       : string
  currency      : string
  timezone      : string
  street        : string
  latitude      : number
  longitude     : number
}

interface AddressComponentsInterface {
  long_name  : string
  short_name : string
  types      : [string]
}
interface GoogleObject {
  results: [
    {
      address_components: [
        AddressComponentsInterface
      ],
      geometry: {
        location: {
          lat: number
          lng: number
        }
      }
    }
  ]
}

const google_api_key = process.env.REACT_APP_GOOGLE_API_KEY

const fetchAndParseAddressData = async (agency_id: string, inputFormData: InputFormDataType) : Promise<{postmanString: string, crmString: string}> => {
  let formattedAddress = `address=${inputFormData.address.split(' ').join('+')}`

  let { data: res } = await axios.get<GoogleObject>(`https://maps.googleapis.com/maps/api/geocode/json?key=${google_api_key}&${formattedAddress}`)

  let rawAddress: RawAddressObject = {
      street_number: '',
      street_name: '',
      city: '',
      province: '',
      country: '',
      zipCode: '',
      currency: '',
      timezone: '',
      street: '',
      latitude: 0,
      longitude: 0,
  }

  res.results[0].address_components.forEach((element: AddressComponentsInterface) => {
    if (element.types[0] === 'street_number') {
      rawAddress.street_number = element.short_name
    }
    if (element.types[0] === 'route') {
      rawAddress.street_name = element.short_name
    }

    if (element.types[0] === 'locality') {
      rawAddress.city = element.short_name
    }

    if (element.types[0] === 'administrative_area_level_1') {
      rawAddress.province = element.long_name
    }

    if (element.types[0] === 'country') {
      rawAddress.country = element.long_name
    }

    if (element.types[0] === 'postal_code') {
      rawAddress.zipCode = element.short_name
    }
  })

  switch(rawAddress.country) {
    case 'Canada':
      rawAddress.currency = 'CAD'
      break
    case 'Australia':
      rawAddress.currency = 'AUD'
      break
    case 'United States':
      rawAddress.currency = 'USD'
      break
  }

  rawAddress.timezone = getTimezone(rawAddress.country, rawAddress.province)
  rawAddress.street = `${rawAddress.street_number} ${rawAddress.street_name}`
  rawAddress.latitude = res.results[0].geometry.location.lat
  rawAddress.longitude = res.results[0].geometry.location.lng


  interface PostmanDataObjectInterface {
    agencyId: string,
    account: {
      name: string,
      currency: string,
      timezone: string,
      website: string,
      street: string,
      city: string,
      province: string,
      country: string,
      zipCode: string,
      latitude: number,
      longitude: number,
      grantMasterUsersAccess: boolean,
    },
  }

  let PostmanDataObject: PostmanDataObjectInterface = {
    agencyId: agency_id,
    account: {
      name: inputFormData.company_name,
      currency: rawAddress.currency,
      timezone: rawAddress.timezone,
      website: inputFormData.website_url,
      street: rawAddress.street,
      city: rawAddress.city,
      province: rawAddress.province,
      country: rawAddress.country,
      zipCode: rawAddress.zipCode,
      latitude: rawAddress.latitude,
      longitude: rawAddress.longitude,
      // New param added to the endpoint to automatically provide TRFFK strategists and TRFFK AdOps account initial permissions on the account
      // Agency ids checked are TRFFK and DealerBreacher
      grantMasterUsersAccess: (agency_id === "5be0248d-d0ec-4491-8588-353d21bfb358" || agency_id === "f6602c4f-aef0-44e1-959c-590a42ea55a7")
    },
  }

  let CRMDataObject: CRMDataObjectInterface = {
    company_name: inputFormData.company_name,
    street: rawAddress.street,
    city: rawAddress.city,
    country: rawAddress.country,
    province: rawAddress.province,
    zipCode: rawAddress.zipCode,
    phone_number: inputFormData.phone_number,
    website_url: inputFormData.website_url,
  }

  return {
    postmanString: JSON.stringify(PostmanDataObject, undefined, 4),
    crmString: crmJavascriptConsoleCode(CRMDataObject)
  }
}

interface CountryInterface {
  [country: string]: {[province: string]: string}
}

const getTimezone = (inputCountry: string, inputProvince: string) => {
  let countryCapitals: CountryInterface = {
    "Canada": {
      "Newfoundland and Labrador": "St.John's",
      "Prince Edward Island": "Charlottetown",
      "Nova Scotia": "Halifax",
      "New Brunswick": "Fredericton",
      "Quebec": "Montreal",
      "Québec": "Montreal",
      "Ontario": "Toronto",
      "Manitoba": "Winnipeg",
      "Saskatchewan": "Regina",
      "Alberta": "Edmonton",
      "British Columbia": "Vancouver",
    },
    'United States': {},
    "Australia": {},
  }

  let cityRaw = "Error Do Not Submit"
  Object.keys(countryCapitals).forEach((country) => {
    if (country === inputCountry) {
      Object.keys(countryCapitals[country]).forEach((province) => {
        if (province === inputProvince) {
          cityRaw = countryCapitals[country][province]
        }
      })
    }
  })

  let timezone
  if (inputCountry === 'Australia') {
    timezone = `Australia/${cityRaw}`
  } else {
    timezone = `America/${cityRaw}`
  }

  return timezone
}

interface CRMDataObjectInterface {
  company_name: string
  street: string
  city: string
  country: string
  province: string
  zipCode: string
  phone_number: string
  website_url: string
}

const crmJavascriptConsoleCode = (myCRMDataObject: CRMDataObjectInterface) => {
  const { company_name, street, city, country, province, zipCode, phone_number, website_url } = myCRMDataObject

  let crmdata = `document.querySelector('[name=orgName]').value = '${company_name}'
document.querySelector('#street').value = '${street}'
document.querySelector('#city').value = '${city}'
document.querySelector('#postalCode').value = '${zipCode}'
document.querySelector('#phone').value = '${phone_number}'
document.querySelector('[name=website]').value = '${website_url}'

// Dealer Button
let radioDealer = document.querySelector('#radioD')
radioDealer.checked = true
switchDealerFields(radioDealer.value)

let myProvince = '${province}'

if(myProvince === 'Québec') {
  myProvince = 'Quebec'
}

if(myProvince === 'British Columbia') {
  myProvince = 'British Colombia'
}


document.querySelector('#country').value = '${country}'
const solarfunction = () => {
  $('#province').prop('disabled', true)
  var country = getReferenceCountry($('#country').val())
  if (!country) {
    alert('ERROR Invalid country')
    return
  }
  $('#province').empty().append('<option selected value="">&nbsp;-&nbsp;</option>')
  $.getJSON('api.php', { action: 'getNewDealerSelectsOptions', type: 'countryState', context: country }, function (data) {
    $.each(data, function (i, item) {
      $('#province').append('<option value="' + data[i].Value + '">' + data[i].Text + '</option>')
    })
    $('#province').prop('disabled', false)
  })
}
solarfunction()

setTimeout(() => {
  let provinces = document.querySelector('#province')
  Array.from(provinces.options).forEach((element) => {
    let target = element.textContent
    var regexObject = new RegExp(myProvince, 'i')

    let result = target.match(regexObject)

    if (result) {
      document.querySelector('#province').value = element.value
    }
  })
}, 1000)
codeAddress()`
  return crmdata
}


export { fetchAndParseAddressData }