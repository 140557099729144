import baseUrl from "./"
import AnalysisJobResult from "../../AnalysisJobResult"

export default (jobId:string) => baseUrl+"SetUpScraping/ScrapingAnalysisResult/JobID/"+jobId

export enum PossibleSuccessMessages {
  RUNNING     = "Job has not finished running",
  ERROR       = "Job has encountered an error",
  NOT_STARTED = "Job has not started yet",
}
export type Success = Array<AnalysisJobResult|PossibleSuccessMessages>

export interface Failure{

}
