import {
  StandardThunk,
  createRetrieveProviders,
  createRetrievingProviders,
}                          from "../creators"
import axios, {
  AxiosError,
}                          from "axios"
import endpoint, {
  Success,
  Failure
}                          from "../../model/http/Scraping/GetProviders"


export const getProviders = ():StandardThunk => (dispatch, getState) => {
  dispatch(createRetrievingProviders(true))
  axios.get<Success>(endpoint, {
    headers : {Authorization: "Bearer "+getState().Login.User.token}
  })
  .then((response)=>{
    dispatch(createRetrieveProviders(response.data))
  })
  .catch((response:AxiosError<Failure>)=>{
    console.log(response)
  })
  .then(()=>{
    dispatch(createRetrievingProviders(false))
  })
}
