import "./AnalysisJobResult.scss"
import * as React                       from "react"
import {JobGeneric, JobType, JobStatus} from "../../../model/Job"
import ScrapingContext                  from "../../../model/ScrapingContext"
import ResultDisplay                    from "./ResultDisplay"
import Dialog from "@material-ui/core/Dialog"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import Slide from "@material-ui/core/Slide"
import { TransitionProps } from "@material-ui/core/transitions"
import CloseIcon from "@material-ui/icons/Close"

interface AnalysisJobResultOwnProps{
  context : ScrapingContext
  job     : JobGeneric<JobType.ContextAnalysis>
}

const Transition = React.forwardRef((props:TransitionProps & { children?: React.ReactElement }, ref)=> {
  return <Slide direction="up" ref={ref} {...props} />;
})

const AnalysisJobResult = (props:AnalysisJobResultOwnProps) => {
  const [showResult, setShowResult] = React.useState<boolean>(false)
  const openResult = ()=>setShowResult(true)
  const hideResult = ()=>setShowResult(false)
  if(props.job.status !== JobStatus.FINISHED || !props.job.result){return <div>Analysis job must have a result and its status must be FINISHED for this component</div>}
  const chosen = props.job.result.find(x=>x.chosen === 1)
  const others = props.job.result.filter(x=>x.chosen === 0)
  return (
    <div className="AnalysisJobResult_Parent">
      <button onClick={openResult}>
        See results
      </button>
      <Dialog fullScreen open={showResult} onClose={hideResult} TransitionComponent={Transition}>
        <AppBar style={{position: "relative", padding: "15px", alignItems: "normal", backgroundColor: "#232323"}}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={hideResult} aria-label="close" style={{backgroundColor: "#181818", color:"#eee"}}>
              <CloseIcon/>
            </IconButton>
            <Typography variant="h6" style={{flex: 1, color: "#eee"}}>
              {props.context.AccountID} {props.context.ContextID} {props.context.VehicleState} {props.context.Lang}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="AnalysisJobResult_ResultsContent">
          <h3>Chosen</h3>
          <div className="ChosenResultDisplay">
            {chosen ? <ResultDisplay result={chosen}/> : <div>NONE</div>}
            {/*chosen ? <ResultDisplay result={chosen}/> : <ResultDisplay result={others[0]}/>*/}
          </div>
          <hr/>
          <h3>Refused</h3>
          <div className="RefusedResultDisplays">
            {others.map(x=>
              <ResultDisplay key={x.jobId} result={x}/>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default AnalysisJobResult
