import {combineReducers} from "redux"
import WithoutVehicles   from "./WithoutVehicles"
import Jobs              from "./Jobs"
import Notes             from "./Notes"
import Inventory         from "./Inventory"
import Hidden            from "./Hidden"
import Retrieving        from "./Retrieving/Retrieving"

const Contexts = combineReducers({
  WithoutVehicles,
  Retrieving,
  Jobs,
  Notes,
  Inventory,
  Hidden,
})

export default Contexts
