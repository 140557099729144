import "./JobPolling.css"
import * as React        from "react"
import {connect}         from "react-redux"
import {ThunkDispatch}   from "redux-thunk"
import {State}           from "../../redux/reducers/"
import {Action}          from "../../model/constant/actions"
import {
  JobStatus,
  JobType
}          from "../../model/Job"
import {
  pollContextScrapingJob
}                        from "../../actions/Scraping/scraping"
import {
  pollContextAnalysisJob
}                        from "../../actions/Scraping/analysis"

interface JobPollingOwnProps{
  frequencySeconds : number
}

const mapStateToProps = (state:State, ownProps:JobPollingOwnProps)=>{
  return {
    jobsPerContext : state.Contexts.Jobs
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:JobPollingOwnProps)=>{
  return {
    pollContextScrapingJob : (...args:Parameters<typeof pollContextScrapingJob>)=>dispatch(pollContextScrapingJob(...args)),
    pollContextAnalysisJob : (...args:Parameters<typeof pollContextAnalysisJob>)=>dispatch(pollContextAnalysisJob(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:JobPollingOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type JobPollingProps = ReturnType<typeof mergeProps>
const JobPolling = (props:JobPollingProps) => {
  if(props.frequencySeconds <= 0){throw Error("Frequency of polling must be higher than 0")}
  const [count, setCount] = React.useState<number>(props.frequencySeconds)
  // eslint-disable-next-line
  React.useEffect(()=>{
    let timer:any
    if(count > 0){
      timer = setTimeout(()=>{setCount(prev=>prev-1)},1000)
    }
    if(count === 0){
      setCount(props.frequencySeconds)
    }
    return () => {if(timer){clearTimeout(timer)}}
  })
  const doPoll = count === 0
  if(doPoll){
    for(const contextId of Object.keys(props.jobsPerContext)){
      if(props.jobsPerContext[contextId].status === JobStatus.RUNNING){
        switch(props.jobsPerContext[contextId].type){
          case JobType.ContextAnalysis:
            props.pollContextAnalysisJob(parseInt(contextId,10), props.jobsPerContext[contextId].id)
            break
          case JobType.ContextScraping:
            props.pollContextScrapingJob(parseInt(contextId,10), props.jobsPerContext[contextId].id)
            break
        }
      }
    }
  }
  return (
    <div className="JobPolling">
      <button onClick={()=>setCount(0)}>⭮</button>
      {doPoll
        ? <span>Polling...</span>
        : <span>Polling in {count}</span>
      }
    </div>
  )
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(JobPolling)
