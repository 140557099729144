import "./Multi.css"
import * as React        from "react"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Checkbox from "@material-ui/core/Checkbox"
import ListItemText from "@material-ui/core/ListItemText"

interface MultiOwnProps{
  options : string[]
  selected : string[]
  onChange : (selected:string[])=>void
}

const Multi = (props:MultiOwnProps) => {
  return (
    <div className="Multi">
      <Select
        multiple
        value={props.selected}
        onChange={(e)=>props.onChange(e.target.value as any)}
        renderValue={(selected)=>{
          if((selected as string[]).length > 1){return (selected as string[]).length+" selected"}
          if((selected as string[]).length === 1){return (selected as string[])[0]}
          return "None selected"
        }}
      >
        {props.options.map(x=>
          <MenuItem key={x} value={x}>
            <Checkbox checked={props.selected.includes(x)}/>
            <ListItemText primary={x}/>
          </MenuItem>
        )}
      </Select>
    </div>
  )
}

export default Multi
