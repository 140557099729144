import "./ResultDisplay.scss"
import * as React        from "react"
import AnalysisJobResult   from "../../../model/AnalysisJobResult"
import chroma from "chroma-js"

interface ResultDisplayOwnProps{
  result : AnalysisJobResult
}

const colors = chroma.scale(['black','white']).mode('lch').colors(100)

const ResultDisplay = (props:ResultDisplayOwnProps) => {
  const [expand, setExpand] = React.useState<boolean>(false)
  return (
    <table className="ResultDisplay">
      <tbody>
        <tr><td>Configuration ID</td><td>{props.result.configKey}</td></tr>
        <tr><td>Job ID</td><td>{props.result.jobId}</td></tr>
        {/*<tr><td>Config key</td><td>{props.result.configKey}</td></tr>*/}
        <tr><td>Vehicles</td><td>{props.result.vehicleCount}</td></tr>
        <tr><td>Valid&nbsp;response</td><td>{props.result.validResponceCode}%</td></tr>
        <tr><td>Percentage</td><td>{props.result.percentage}%</td></tr>
        {expand
          ? <>
            <tr><td colSpan={2} style={{textAlign:"center"}}>
              <button onClick={()=>setExpand(false)}>Close details</button>
            </td></tr>
            {Object.keys(props.result.averageSuccessRateByAttribute).map(k=>{
              const value = parseInt((props.result.averageSuccessRateByAttribute as any)[k],10)
              return <React.Fragment key={k}><tr className="ResultDisplay_Bar">
                <td>{k}</td><td>
                  <div>{value}%</div>
                  <div>
                    <div style={{display:"inline-block", width:value+"%", backgroundColor:colors[value-1]}}>&nbsp;</div>
                    <div style={{display:"inline-block", width:100-value+"%"}}>&nbsp;</div>
                  </div>
                  </td>
              </tr></React.Fragment>
            })}
            <tr><td colSpan={2} style={{textAlign:"center"}}>
              <button onClick={()=>setExpand(false)}>Close details</button>
            </td></tr>
          </>
          : <tr><td colSpan={2} style={{textAlign:"center"}}>
            <button onClick={()=>setExpand(true)}>Show details</button>
          </td></tr>
        }
      </tbody>
    </table>
  )
}

export default ResultDisplay
