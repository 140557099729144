import React, { useState } from 'react'

import ContactItemInput from './contact_item_input/ContactItemInput'
import { InputFormDataType } from '../../../../model/accountCreator/form_types'
import loading8new from '../../media/loading8new.gif'
import './Form.scss'

interface FormForAccountCreator {
  loading: boolean
  handleFormOnSubmit: (inputFormData: InputFormDataType) => void
}

const Form = (props: FormForAccountCreator) => {
  const [form_data, setFormData] = useState<InputFormDataType>({
    company_name: '',
    website_url: '',
    address: '',
    phone_number: '',
  })

  const changer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {id, value} = e.target
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [id]: value
      }
    })
  }

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.handleFormOnSubmit(form_data)
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <div className="form_flexbox mb-5-removable">
        <ContactItemInput labelText="Company Name" dataType="text" handleOnChange={changer} inputName={'company_name'} />
        <ContactItemInput labelText="Website URL" dataType="text" handleOnChange={changer} inputName={'website_url'} />
      </div>

      <div className="form_flexbox">
        <ContactItemInput labelText="Address" dataType="text" handleOnChange={changer} inputName={'address'} />
        <ContactItemInput labelText="Phone Number" dataType="text" handleOnChange={changer} inputName={'phone_number'} />
      </div>

      {props.loading
      ? <img className="mt-8" src={loading8new} alt="Loading..."/>
      : <div className="my-4">
        <button className={`btn btn_block`}>
          <span> Generate </span>
        </button>
      </div>}
    </form>
  )
}

export default Form
