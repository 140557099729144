import baseUrl from "./"
import ScrapingContext from "../../ScrapingContext"

export default baseUrl+"Monitoring/ActiveContextsWithoutVehicles"

export type Success = Array<ScrapingContext>

export interface Failure{

}
