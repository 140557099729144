import {createReducer} from "../../../utils/Reducers"
import ActionTypes     from "../../../model/constant/actions"
import {User}          from "../../../model/users/User"

const UserReducer = createReducer<User>(null, {
  [ActionTypes.LOGIN] : (state, action)  => action.payload,
  [ActionTypes.LOGOUT] : (state, action) => null,
})

export default UserReducer
