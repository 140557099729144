import {useSelector, useDispatch} from "react-redux"
import {State}                    from "../redux/reducers/"
import {getProviders}             from "../actions/Scraping/providers"

const useLoadProviders = () => {
  const retrieving = useSelector((state:State)=>state.Providers.Retrieving)
  const providers = useSelector((state:State)=>state.Providers.List)
  const dispatch = useDispatch()
  if(!providers && !retrieving){
    dispatch(getProviders())
  }
  return {
    providers,
    loadingProviders : !providers || retrieving
  }
}

export default useLoadProviders
