import { Action as BaseAction }   from "redux"
import {User}                     from "../users/User"
import ScrapingContext            from "../ScrapingContext"
import Account                    from "../Account"
import Agency                     from "../Agency"
import Vehicle                    from "../Vehicle"
import {
  JobGeneric,
  JobType,
  JobDetailsMap
}                                 from "../Job"
import {CallHistoryMethodAction}  from "connected-react-router"

enum ActionTypes {
  LOGIN                                = "LOGIN",
  LOGOUT                               = "LOGOUT",
  FAILED_LOGIN                         = "FAILED_LOGIN",
  LOGGING_IN                           = "LOGGING_IN",
  RETRIEVING_CONTEXTS_WITHOUT_VEHICLES = "RETRIEVING_CONTEXTS_WITHOUT_VEHICLES",
  RETRIEVE_CONTEXTS_WITHOUT_VEHICLES   = "RETRIEVE_CONTEXTS_WITHOUT_VEHICLES",
  CLEAR_CONTEXTS_WITHOUT_VEHICLES      = "CLEAR_CONTEXTS_WITHOUT_VEHICLES",
  RETRIEVING_ACCOUNTS                  = "RETRIEVING_ACCOUNTS",
  RETRIEVE_ACCOUNTS                    = "RETRIEVE_ACCOUNTS",
  ADD_CONTEXT_ANALYSIS_JOB             = "ADD_CONTEXT_ANALYSIS_JOB",
  POLLING_CONTEXT_ANALYSIS_JOB         = "POLLING_CONTEXT_ANALYSIS_JOB",
  PUT_CONTEXT_ANALYSIS_JOB             = "PUT_CONTEXT_ANALYSIS_JOB",
  ADD_CONTEXT_SCRAPING_JOB             = "ADD_CONTEXT_SCRAPING_JOB",
  POLLING_CONTEXT_SCRAPING_JOB         = "POLLING_CONTEXT_SCRAPING_JOB",
  PUT_CONTEXT_SCRAPING_JOB             = "PUT_CONTEXT_SCRAPING_JOB",
  RETRIEVING_PROVIDERS                 = "RETRIEVING_PROVIDERS",
  RETRIEVE_PROVIDERS                   = "RETRIEVE_PROVIDERS",
  ADD_CONTEXT_NOTE                     = "ADD_CONTEXT_NOTE",
  REMOVE_CONTEXT_NOTE                  = "REMOVE_CONTEXT_NOTE",
  FORGET_CONTEXT_JOB                   = "FORGET_CONTEXT_JOB",
  RETRIEVING_CONTEXT_INVENTORY         = "RETRIEVING_CONTEXT_INVENTORY",
  RETRIEVE_CONTEXT_INVENTORY           = "RETRIEVE_CONTEXT_INVENTORY",
  SET_CONTEXT_HIDDEN_STATE             = "SET_CONTEXT_HIDDEN_STATE",
  RETRIEVING_AGENCIES                  = "RETRIEVING_AGENCIES",
  RETRIEVE_AGENCIES                    = "RETRIEVE_AGENCIES",
}
export default ActionTypes

type ActionMap = {
  [ActionTypes.LOGIN]                                : User
  [ActionTypes.LOGOUT]                               : {}
  [ActionTypes.FAILED_LOGIN]                         : string
  [ActionTypes.LOGGING_IN]                           : boolean
  [ActionTypes.RETRIEVING_CONTEXTS_WITHOUT_VEHICLES] : boolean
  [ActionTypes.RETRIEVE_CONTEXTS_WITHOUT_VEHICLES]   : ScrapingContext[]
  [ActionTypes.RETRIEVING_ACCOUNTS]                  : boolean
  [ActionTypes.RETRIEVE_ACCOUNTS]                    : Account[]
  [ActionTypes.ADD_CONTEXT_ANALYSIS_JOB]             : {contextId: number, jobId: string, jobDetails: JobDetailsMap[JobType.ContextAnalysis]}
  [ActionTypes.POLLING_CONTEXT_ANALYSIS_JOB]         : {contextId: number, polling: boolean}
  [ActionTypes.PUT_CONTEXT_ANALYSIS_JOB]             : {contextId: number, job: Partial<JobGeneric<JobType.ContextAnalysis>>}
  [ActionTypes.ADD_CONTEXT_SCRAPING_JOB]             : {contextId: number, jobId: string, jobDetails: JobDetailsMap[JobType.ContextScraping]}
  [ActionTypes.POLLING_CONTEXT_SCRAPING_JOB]         : {contextId: number, polling: boolean}
  [ActionTypes.PUT_CONTEXT_SCRAPING_JOB]             : {contextId: number, job: Partial<JobGeneric<JobType.ContextScraping>>}
  [ActionTypes.RETRIEVING_PROVIDERS]                 : boolean
  [ActionTypes.RETRIEVE_PROVIDERS]                   : string[]
  [ActionTypes.ADD_CONTEXT_NOTE]                     : {contextId: number, text: string, who: string}
  [ActionTypes.REMOVE_CONTEXT_NOTE]                  : {contextId: number, noteId: string}
  [ActionTypes.CLEAR_CONTEXTS_WITHOUT_VEHICLES]      : {}
  [ActionTypes.FORGET_CONTEXT_JOB]                   : {contextId: number}
  [ActionTypes.RETRIEVING_CONTEXT_INVENTORY]         : {contextId: number, status: boolean}
  [ActionTypes.RETRIEVE_CONTEXT_INVENTORY]           : {contextId: number, inventory: Vehicle[]}
  [ActionTypes.SET_CONTEXT_HIDDEN_STATE]             : {contextId: number, hidden: boolean}
  [ActionTypes.RETRIEVING_AGENCIES]                  : boolean
  [ActionTypes.RETRIEVE_AGENCIES]                    : Agency[]
}

export type ActionType = keyof ActionMap
export type ActionPayload<K extends ActionType> = ActionMap[K]
export interface ActionObject<K extends ActionType> extends BaseAction {
  type    : K
  payload : ActionPayload<K>
}
export type Action = {
  [P in ActionType]: ActionObject<P>
}[ActionType]
export type DispatchableAction = Action | CallHistoryMethodAction
