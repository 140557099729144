import {useSelector, useDispatch}   from "react-redux"
import {State}                      from "../redux/reducers/"
import {getAgencies}                from "../actions/Auth2/agencies"

const useLoadAgencies = () => {
  const retrieving = useSelector((state:State)=>state.Agencies.Retrieving)
  const agenciesList = useSelector((state:State)=>state.Agencies.List)
  const dispatch = useDispatch()
  if(!agenciesList && !retrieving){
    dispatch(getAgencies())
  }
  return {
    agenciesList,
    loadingAgencies : !agenciesList || retrieving
  }
}

export default useLoadAgencies
