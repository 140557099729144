import './App.css'
import React from 'react'
import EntryRouting from "./routing"
import './boilerplate.scss'

function App() {
  return (
    <div className="App">
      <EntryRouting/>
    </div>
  )
}

export default App