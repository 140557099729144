import {combineReducers} from "redux"
import List           from "./List"
import Retrieving     from "./Retrieving"

const Agencies = combineReducers({
  List,
  Retrieving,
})

export default Agencies
