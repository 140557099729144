import baseUrl from "./"

export default (contextId:number) => baseUrl+"Context/"+contextId+"/RequestRunScraping"

export interface Success {
  message : string
  jobId   : string
}

export interface Failure{

}
