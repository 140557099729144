import React from 'react'
import './ContactItemInputAndTextarea.scss'

interface ContactItemInputInterface {
  labelText: string
  dataType: string
  inputName: string
  handleOnChange: (e:React.ChangeEvent<HTMLInputElement>) => void
  handleOnKeyDown?: (e:React.KeyboardEvent<HTMLDivElement>) => void
}

const ContactItemInput = (props: ContactItemInputInterface) => {

  return (
    <div className="contact_container">
      <div className="contact_group contact_fixed_size">
        <input
          className="contact_group_input"
          type={props.dataType}
          id={props.inputName}
          placeholder=" "
          required
          onChange={props.handleOnChange}
          onKeyDown={props.handleOnKeyDown}
          name={props.inputName}
        />
        <label className="contact_group_label" htmlFor={props.inputName}>
          {props.labelText + ':'}
        </label>
        <div className="contact_group_line_container"></div>
      </div>
    </div>
  )
}

export default ContactItemInput
