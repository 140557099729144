import "./index.scss"
import React, { useState, useEffect }     from 'react'
import { fetchAndParseAddressData }       from './logic/logic'
import Title                              from '../../components/Title/Title'
import Form                               from './components/form/Form'
import ContactitemTextArea                from './components/form/contact_item_input/ContactItemTextarea'
import Modal                              from './components/modal/Modal'
import { InputFormDataType }              from '../../model/accountCreator/form_types'
import {
  Link,
}                                         from "react-router-dom"
import useLoadAgencies                    from "../../hooks/useLoadAgencies"

interface AccountActivationProps {}

interface FormObjectInterface {
  json_data : string
  crm_data : string
  agency_id : string
  agency_name : string
}

const AccountActivation = () => {
  const {agenciesList, loadingAgencies} = useLoadAgencies()
  const [formObject, setFormObject] = useState<FormObjectInterface>({
      json_data: '',
      crm_data: '',
      agency_id: '',
      agency_name: ''
  })
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
      if(agenciesList) {
        setFormObject({
          json_data: '',
          crm_data: '',
          agency_id: agenciesList[6].id,
          agency_name: agenciesList[6].name,
        })
      }
      // eslint-disable-next-line
    }, [agenciesList])
    if(loadingAgencies) {
      return <div className="AccountCreator">loading</div>
    }

    const changer = (e: any) => {
      const { id, value } = e.target
      setFormObject((prevFormObject) => {
        return {
          ...prevFormObject,
          [id]: value,
        }
      })
    }

    const formOnSubmit = async (inputFormData: InputFormDataType) => {
      setLoading(true)
      let arrayData = await fetchAndParseAddressData(formObject.agency_id, inputFormData)
      setFormObject((prevFormObject) => {
        return {
          ...prevFormObject,
          json_data: arrayData.postmanString,
          crm_data: arrayData.crmString,
        }
      })
      setLoading(false)
    }

    const changeAgencyClickListener = (input_agency_id: string, input_agency_name: string) => {
      setFormObject((prevFormObject) => {
        return {
          ...prevFormObject,
          agency_id: input_agency_id,
          agency_name: input_agency_name,
        }
      })
    }

    return (
      <div className="AccountCreator">
        <Title h1title={'Scraping Desk'} />
        <div className="mt-8">
          <Link className={`btn goToScrapingDesk`} to={'/NoInventory'}>Zero Inventory</Link>
        </div>
        <div className="border">
          <div className={"agency"} onClick={() => setModalOpen(true)}>
            <span className="agency_text">Agency: {formObject.agency_name}</span>
          </div>
          <div className="mt-6">
            <Form loading={loading} handleFormOnSubmit={formOnSubmit} />
          </div>
        </div>
        <div className="my-4">
          <ContactitemTextArea labelText="JSON Data" inputValue={formObject.json_data} handleOnChange={changer} inputName={'json_data'} />
        </div>
        <div>
          <ContactitemTextArea labelText="CRM Data" inputValue={formObject.crm_data} handleOnChange={changer} inputName={'crm_data'} />
          <div className="button-group">
            <a href="https://crm-dealerbreacher.com/CRM/?action=dealer_new" target="_blank" rel="noopener noreferrer" className={`btn`}>Create CRM Dealer</a>
            <a href="https://crm-dealerbreacher.com/CRM/?action=create_new_account_from_scratch" target="_blank" rel="noopener noreferrer" className={`btn`}>Link CRM Dealer</a>
          </div>
        </div>

        {modalOpen && <Modal modalOpen={modalOpen} onClose={() => setModalOpen(false)} modalData={agenciesList} handleChangeAgencyClickListener={changeAgencyClickListener} />}
      </div>
    )
}

export default AccountActivation
