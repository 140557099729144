import * as React        from "react"
import {connect}         from "react-redux"
import {State}           from "../redux/reducers/"
import {DispatchableAction}          from "../model/constant/actions"
import {Permission}          from "../model/users/Permissions"
import {
  Switch,
  Route,
  Redirect
}                        from "react-router-dom"
import { ThunkDispatch } from "redux-thunk"
import NoInventory from "../pages/NoInventory"
import AccountActivation from "../pages/AccountActivation"

interface LoggedRoutingOwnProps{
  path ?: string
}

const makeMapStateToProps = () => {
  return (state:State, ownProps:LoggedRoutingOwnProps)=>{
    return {
      permissions : state.Login.User.permissions
    }
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:LoggedRoutingOwnProps)=>{
  return {}
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:LoggedRoutingOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoggedRoutingProps = ReturnType<typeof mergeProps>
const LoggedRouting:React.SFC<LoggedRoutingProps> = (props:LoggedRoutingProps) => {
  if(!props.permissions || !props.permissions.some(x=>x.AccountID === null && x.Permissions.some(per=>per === Permission.Scraping_Edit))){
    return <div>Permission denied - `{Permission.Scraping_Edit}` required on all accounts.</div>
  }
  const previousPath = ("/"+props.path+"/").replace("//","/")
  return (
    <div>
      <Switch>
        <Route path={previousPath+"NoInventory"} component={NoInventory}/>
        <Route path={previousPath+"Activation"} component={AccountActivation}/>
        <Redirect to={previousPath+"NoInventory"}/>
      </Switch>
    </div>
  )
}
LoggedRouting.defaultProps = {
  path : ""
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(LoggedRouting)