import {
  Dispatch,
  Middleware
}                          from "redux"
import {State}             from "../reducers/"
import {Action}            from "../../model/constant/actions"

const logger:Middleware<Dispatch<Action>, State> = (store) => (next) => (action) => {
  console.group(action.type)
  console.info('dispatching', action)
  const result = next(action)
  console.log('next state', store.getState())
  console.groupEnd()
  return result
}

export default logger
