import {combineReducers} from "redux"
import User              from "./User"
import Error             from "./Error"
import LoggingIn         from "./LoggingIn"

const Login = combineReducers({
  User,
  Error,
  LoggingIn,
})

export default Login
