import {combineReducers} from "redux"
import List           from "./List"
import ById           from "./ById"
import Retrieving     from "./Retrieving"

const Accounts = combineReducers({
  List,
  ById,
  Retrieving,
})

export default Accounts
