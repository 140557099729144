import "./Message.css"
import * as React                           from "react"
import Note                                 from "../../model/Note"
import DeleteIcon                           from "@material-ui/icons/Delete"
import IconButton                           from "@material-ui/core/IconButton"
import Tooltip                              from "@material-ui/core/Tooltip"


interface MessageProps{
  message                : Note
  onMessageDelete        : (messageId:string)=>void
  expired               ?: boolean
  expiredMessageTooltip ?: string
}

const Message = (props:MessageProps) => {
  return (
    <div className={"Message"+(props.expired?" Expired":"")}>
      <div className="MessageWho">{props.message.who}</div>
      <Tooltip open={!props.expired ? false : undefined} title={props.expiredMessageTooltip || "This message is expired."}>
        <div className="MessageWhen">{props.message.when.format("YYYY-MM-DD hh:mm:ss")}</div>
      </Tooltip>
      <IconButton
        className="MessageDeleteButton"
        component="div"
        disableRipple
        disableFocusRipple
        size="small"
        onClick={()=>props.onMessageDelete(props.message.id)}
      >
        <DeleteIcon
          fontSize="small"
        />
      </IconButton>
      <p className="MessageText">{props.message.text}</p>
    </div>
  )
}

export default Message