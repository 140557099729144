import {
  StandardThunk,
  createLogin,
  createFailedLogin,
  createLoggingIn,
}                                      from "../creators"
import axios, {
  AxiosError,
}                                      from "axios"
import Cookies                         from "js-cookie"
import auth                            from "../../model/http/Auth/"
import {Success, Failure}              from "../../model/http/Auth/Login"
import {stringify}                     from "querystring"

const extractErrorMessage = (response?:Success|Failure):string => {
  if(response && response.error && Array.isArray(response.error) && response.error.length){
    return response.error[0]
  }
  return "Unknown error while authenticating"
}
//Only secure cookies to true when not in dev env otherwise remember me won't work and would need to click login everytime it compiles
const IS_DEV_ENV = process.env.NODE_ENV === 'development'

export const login = (email:string, password:string, rememberMe:boolean=false):StandardThunk => (dispatch) => {
  dispatch(createLoggingIn(true))
  const payload = {
    action : "Login",
    email,
    password,
    rememberMe,
  }
  axios.post<Success>(auth, stringify(payload))
  .then((response)=>{
    if(response.data.error && response.data.error.length){
      dispatch(createFailedLogin(extractErrorMessage(response.data)))
    }
    else{
      if(rememberMe && response.data.data.rememberMe){
        Cookies.set("email", email,{sameSite:'strict',secure:!IS_DEV_ENV})
        Cookies.set("UID", response.data.data.rememberMe,{sameSite:'strict',secure:!IS_DEV_ENV})
      }
      dispatch(createLogin(response.data.data))
    }
  })
  .catch((response:AxiosError<Failure>)=>{
    dispatch(createFailedLogin(extractErrorMessage(response.response ? response.response.data : undefined)))
  })
  .then(()=>{
    dispatch(createLoggingIn(false))
  })
}

export const rememberMeLogin = (email:string, rememberMeId:string):StandardThunk => (dispatch) => {
  dispatch(createLoggingIn(true))
  const payload = {
    action : "RememberMe",
    email,
    rememberMeId,
  }
  axios.post<Success>(auth, stringify(payload))
  .then((response)=>{
    if(response.data.error && response.data.error.length){
      // If the remember fails, forget the cookies so to not spam the endpoint
      Cookies.remove("email")
      Cookies.remove("UID")
      dispatch(createFailedLogin("Failed to remember you: "+extractErrorMessage(response.data)))
    }
    else{
      dispatch(createLogin(response.data.data))
    }
  })
  .catch((response:AxiosError<Failure>)=>{
    dispatch(createFailedLogin("Failed to remember you: "+extractErrorMessage(response.response ? response.response.data : undefined)))
  })
  .then(()=>{
    dispatch(createLoggingIn(false))
  })
}
