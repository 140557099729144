import "./ContextHiddenButton.css"
import * as React               from "react"
import {useDispatch}            from "react-redux"
import {createSetContextHiddenState} from "../../actions/creators"
import IconButton                from "@material-ui/core/IconButton"
import VisibilityIcon            from "@material-ui/icons/Visibility"
import VisibilityOffIcon         from "@material-ui/icons/VisibilityOff"

interface ContextHiddenButtonOwnProps{
  contextId : number
  hidden   ?: boolean
}

const ContextHiddenButton = (props:ContextHiddenButtonOwnProps) => {
  const dispatch = useDispatch()
  const invertVisibility = () => {
    dispatch(createSetContextHiddenState({contextId: props.contextId, hidden: !Boolean(props.hidden)}))
  }
  return (
    <IconButton
      className="ContextHiddenButton"
      component={"div"}
      disableRipple
      disableFocusRipple
      onClick={invertVisibility}
    >
      {props.hidden ? <VisibilityOffIcon/> : <VisibilityIcon/>}
    </IconButton>
  )
}

export default ContextHiddenButton
