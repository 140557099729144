import {useSelector, useDispatch}   from "react-redux"
import {State}                      from "../redux/reducers/"
import {getAccounts}                from "../actions/Auth2/accounts"

const useLoadAccounts = () => {
  const retrieving = useSelector((state:State)=>state.Accounts.Retrieving)
  const accountsList = useSelector((state:State)=>state.Accounts.List)
  const accountsById = useSelector((state:State)=>state.Accounts.ById)
  const dispatch = useDispatch()
  if(!accountsList && !retrieving){
    dispatch(getAccounts())
  }
  return {
    accountsList,
    accountsById,
    loadingAccounts : !accountsList || retrieving
  }
}

export default useLoadAccounts
