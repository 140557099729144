import baseUrl from "./"

export default (jobId:string) => baseUrl+"horizon/api/jobs/failed/"+jobId // failed endpoint works wether the job failed or not

export enum HorizonJobStatus {
  COMPLETED = "completed",
  FAILED = "failed",
}

export interface Success{
  id           : string
  connection   : string
  queue        : string
  name         : string
  status       : string
  exception    : unknown
  failed_at    : unknown
  completed_at : string
  retried_by   : unknown
  reserved_at  : string
  index        : unknown
  payload      : {
    type           : string
    timeout        : unknown
    tags           : string[]
    id             : string
    displayName    : string
    timeoutAt      : unknown
    pushedAt       : number
    maxTries       : unknown
    job            : string
    attempts       : number
    data           : {
      command          : string
      commandName      : string
    }
  }
}

export interface Failure {

}
