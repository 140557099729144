import {combineReducers}   from "redux"
import {connectRouter}     from "connected-react-router"
import {History}           from "history"
import Login               from "./Login/Login"
import Contexts            from "./Contexts/Contexts"
import Accounts            from "./Accounts/Accounts"
import Providers           from "./Providers/Providers"
import Agencies           from "./Agencies/Agencies"

const createRootReducer = (history:History) => combineReducers({
  router : connectRouter(history),
  Login,
  Contexts,
  Accounts,
  Providers,
  Agencies,
})
export default createRootReducer

export type State = ReturnType<ReturnType<typeof createRootReducer>>
