import moment from "moment"
import AnalysisJobResult from "./AnalysisJobResult"
import {Success as GeneralJobResult} from "./http/Scraping/HorizonJob"

export enum JobType {
  ContextScraping = "ContextScraping",
  ContextAnalysis = "ContextAnalysis",
}

export type JobResultMap = {
  [JobType.ContextAnalysis] : AnalysisJobResult[]
  [JobType.ContextScraping] : GeneralJobResult
}
export type JobDetailsMap = {
  [JobType.ContextAnalysis] : {provider: string}
  [JobType.ContextScraping] : {}
}

export enum JobStatus {
  RUNNING  = "RUNNING",
  FINISHED = "FINISHED",
  FAILED   = "FAILED",
}

export interface JobGeneric <T extends JobType>{
  id : string
  type : T
  status : JobStatus
  started : moment.Moment
  polling : boolean
  details : JobDetailsMap[T]
  result ?: JobResultMap[T]
}

export type Job = {
  [T in JobType] : JobGeneric<T>
}[JobType]
export default Job
