export enum Permission{
  Keystone_Standard = "Keystone_Standard",
  Scraping_Edit     = "Scraping_Edit",
}

export interface PermissionGroup{
  ID          : string
  UserID      : string
  AccountID   : string|null,
  Permissions : Permission[]
}
