import "./ContextNotes.css"
import * as React                   from "react"
import ScrapingContext              from "../../model/ScrapingContext"
import {useSelector, useDispatch}   from "react-redux"
import {State}                      from "../../redux/reducers/"
import {
  createAddContextNote,
  createRemoveContextNote
}                                   from "../../actions/creators"
import MessageThread                from "../../components/MessageThread/MessageThread"

interface ContextNotesOwnProps{
  context : ScrapingContext
}

const ContextNotes = (props:ContextNotesOwnProps) => {
  const dispatch = useDispatch()
  const allContextsNotes = useSelector((state:State)=>state.Contexts.Notes)
  const user = useSelector((state:State)=>state.Login.User.user)
  const notes = allContextsNotes[props.context.ContextID] || []
  return (
    <div className="ContextNotes">
      <MessageThread
        messages={notes}
        title={`${props.context.ContextID} ${props.context.VehicleState} ${props.context.Lang}`}
        onMessageCreate={(text)=>{dispatch(createAddContextNote({contextId: props.context.ContextID, text, who: user.firstName+" "+user.lastName}))}}
        onMessageDelete={(noteId)=>{dispatch(createRemoveContextNote({contextId: props.context.ContextID, noteId}))}}
        dayCountBeforeMessageExpired={props.context.DayCountSinceNoInventory}
        expiredMessageTooltip="This note was made before the day count since no inventory of the context."
      />
    </div>
  )
}

export default ContextNotes
