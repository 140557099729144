import {
  StandardThunk,
  createAddContextAnalysisJob,
  createPutContextAnalysisJob,
  createPollingContextAnalysisJob
}                          from "../creators"
import axios, {
  AxiosError,
}                          from "axios"
import runEndpoint, {
  Success as RunSuccess,
  Failure as RunFailure
}                          from "../../model/http/Scraping/RunContextAnalysis"
import resultEndpoint, {
  PossibleSuccessMessages,
  Success as ResultSuccess,
  Failure as ResultFailure
}                          from "../../model/http/Scraping/GetContextAnalysisResult"
import {JobStatus}                          from "../../model/Job"


export const startContextAnalysisJob = (contextId:number, provider:string):StandardThunk => (dispatch, getState) => {
  axios.post<RunSuccess>(runEndpoint(contextId, provider), {}, {
    headers : {Authorization: "Bearer "+getState().Login.User.token}
  })
  .then((response)=>{
    dispatch(createAddContextAnalysisJob({
      contextId,
      jobId : response.data.jobId,
      jobDetails : {provider}
    }))
  })
  .catch((response:AxiosError<RunFailure>)=>{
    console.log(response)
  })
}
export const pollContextAnalysisJob = (contextId:number, jobId:string):StandardThunk => (dispatch, getState) => {
  dispatch(createPollingContextAnalysisJob({contextId, polling: true}))
  axios.get<ResultSuccess>(resultEndpoint(jobId), {
    headers : {Authorization: "Bearer "+getState().Login.User.token}
  })
  .then((response)=>{
    if(response.data){
      if(Array.isArray(response.data)){
        dispatch(createPutContextAnalysisJob({contextId, job: {result: (response.data as any), status: JobStatus.FINISHED}}))
      }
      else if(response.data === PossibleSuccessMessages.ERROR){
        dispatch(createPutContextAnalysisJob({contextId, job: {status: JobStatus.FAILED}}))
      }
    }
  })
  .catch((response:AxiosError<ResultFailure>)=>{
    console.log(response)
  })
  .then(()=>{
    dispatch(createPollingContextAnalysisJob({contextId, polling: false}))
  })
}
