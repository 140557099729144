import "./index.scss"
import * as React                           from "react"
import {connect}                            from "react-redux"
import {ThunkDispatch}                      from "redux-thunk"
import {State}                              from "../../redux/reducers/"
import {Action}                             from "../../model/constant/actions"
import ScrapingContext                      from "../../model/ScrapingContext"
import {JobStatus}                          from "../../model/Job"
import {
  getContextsWithoutVehicles
}                                           from "../../actions/Scraping/context"
import {
  getAccounts
}                                           from "../../actions/Auth2/accounts"
import {createClearContextsWithoutVehicles} from "../../actions/creators"
import Context                              from "./Context"
import JobPolling                           from "./JobPolling"
import useLoadProviders                     from "../../hooks/useLoadProviders"
import useLoadContextsWithoutVehicles       from "../../hooks/useLoadContextsWithoutVehicles"
import useLoadAccounts                      from "../../hooks/useLoadAccounts"
import MultiSelect                          from "../../components/selects/Multi"
import Title                                from '../../components/Title/Title'
import {
  Link,
}                        from "react-router-dom"

interface NoInventoryOwnProps{
}

const mapStateToProps = (state:State, ownProps:NoInventoryOwnProps)=>{
  return {
    jobsByContext   : state.Contexts.Jobs,
    hiddenByContext : state.Contexts.Hidden
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:NoInventoryOwnProps)=>{
  return {
    getContextsWithoutVehicles : (...args:Parameters<typeof getContextsWithoutVehicles>)=>dispatch(getContextsWithoutVehicles(...args)),
    getAccounts : (...args:Parameters<typeof getAccounts>)=>dispatch(getAccounts(...args)),
    clearContextsWithoutVehicle : ()=>dispatch(createClearContextsWithoutVehicles({}))
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:NoInventoryOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

const sortContexts = (contexts:ScrapingContext[]):ScrapingContext[] => {
  return [...contexts].sort((a,b)=>{
    if(a.DayCountSinceNoInventory > b.DayCountSinceNoInventory){return 1}
    if(a.DayCountSinceNoInventory < b.DayCountSinceNoInventory){return -1}
    if(a.AccountID > b.AccountID){return 1}
    if(a.AccountID < b.AccountID){return -1}
    return 0
  })
}

const jobFilterOptions = ["Standby","Running","Finished"]

type NoInventoryProps = ReturnType<typeof mergeProps>
const NoInventory = (props:NoInventoryProps) => {
  const [amountShown, setAmountShown] = React.useState<number>(20)
  const [jobFilters, setJobFilters] = React.useState<string[]>(jobFilterOptions)
  const [showHidden, setShowHidden] = React.useState<boolean>(false)
  const {accountsById, loadingAccounts} = useLoadAccounts()
  const {providers, loadingProviders} = useLoadProviders()
  const {contextsWithoutVehicles, loadingContextsWithoutVehicles} = useLoadContextsWithoutVehicles()
  if(loadingContextsWithoutVehicles || loadingAccounts || loadingProviders){
    return <div className='NoInventory'>Loading</div>
  }
  const filterContextFunc = (context:ScrapingContext):boolean => {
    if(!showHidden && props.hiddenByContext[context.ContextID]){return false}
    if(!props.jobsByContext[context.ContextID]){return jobFilters.includes("Standby")}
    if(props.jobsByContext[context.ContextID].status === JobStatus.RUNNING){return jobFilters.includes("Running")}
    return jobFilters.includes("Finished")
  }
  const sorted = sortContexts(contextsWithoutVehicles.filter(filterContextFunc)).slice(0, amountShown)
  const hiddenCount = Object.keys(props.hiddenByContext).filter(x=>props.hiddenByContext[x]).length
  return (
    <div className="NoInventory">
      <Title h1title={'Scraping Desk'} />
      <div className="Options">
        <input value={amountShown} onChange={(e)=>{setAmountShown(parseInt(e.target.value, 10))}} type="number"/><br/>
        <button onClick={props.clearContextsWithoutVehicle}>Refresh</button>
        <MultiSelect
          options={jobFilterOptions}
          selected={jobFilters}
          onChange={(selected)=>setJobFilters(selected)}
          />
        <Link className={`btn btn_red`} to={'/Activation'}>Account Creator</Link>
      </div>
      <div className="Options2">
        <div className="showHidden">
          Show hidden ({hiddenCount})
          <input type="checkbox" checked={showHidden} onChange={()=>setShowHidden(prev=>!prev)}/>
        </div>
        <JobPolling frequencySeconds={10}/>
      </div>
      <div className="ContextList">
        {sorted.map(context=>
          <Context
            key={context.ContextID}
            context={context}
            clientName={accountsById[context.AccountID]?.name}
            job={props.jobsByContext[context.ContextID]}
            providers={providers}
            hidden={props.hiddenByContext[context.ContextID]}
          />
        )}
      </div>
    </div>
  )
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(NoInventory)
