import "./MessageThread.css"
import * as React                           from "react"
import moment                               from "moment"
import Note                                 from "../../model/Note"
import CommentIcon                          from "@material-ui/icons/Comment"
import IconButton                           from "@material-ui/core/IconButton"
import Button                               from "@material-ui/core/Button"
import Dialog                               from "@material-ui/core/Dialog"
import DialogActions                        from "@material-ui/core/DialogActions"
import DialogContent                        from "@material-ui/core/DialogContent"
import DialogTitle                          from "@material-ui/core/DialogTitle"
import Paper, { PaperProps }                from "@material-ui/core/Paper"
import Draggable                            from "react-draggable"
import MessageList                          from "./MessageList"

interface MessageThreadOwnProps{
  messages : Note[]
  title : string
  onMessageCreate : (messageText:string)=>void
  onMessageDelete : (messageId:string)=>void
  dayCountBeforeMessageExpired ?: number
  expiredMessageTooltip ?: string
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const MessageThread = (props:MessageThreadOwnProps) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [messageText, setMessageText] = React.useState<string>("")
  const [keepModalOpen, setKeepModalOpen] = React.useState<boolean>(false)
  const handleOpen = ()=>{
    setOpen(true)
  }
  const handleClose = ()=>{
    setOpen(false)
    if(keepModalOpen){setKeepModalOpen(false)}
  }
  const handleAddMessage = ()=>{
    if(messageText.trim().length > 0){
      props.onMessageCreate(messageText.trim())
      setMessageText("")
      if(!keepModalOpen){handleClose()}
    }
  }
  const hasFreshMessage = (
    (props.dayCountBeforeMessageExpired === undefined && props.messages.length > 0) ||
    props.messages.some(x=>!x.when.isBefore(moment().subtract(props.dayCountBeforeMessageExpired, "days"), "days"))
  )
  return (
    <div className="MessageThread">
      <IconButton
        className="MessageThreadOpenButton"
        component="div"
        disableRipple
        disableFocusRipple
        onClick={handleOpen}
      >
        <CommentIcon style={{color: hasFreshMessage ? "#f9c412" : "#101010"}}/>
      </IconButton>
      <Dialog
        className="MessageThreadDialog"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        disableScrollLock
        fullWidth
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <MessageList
            messages={props.messages}
            dayCountBeforeMessageExpired={props.dayCountBeforeMessageExpired}
            onMessageDelete={props.onMessageDelete}
            expiredMessageTooltip={props.expiredMessageTooltip}
          />
        </DialogContent>
        <DialogActions>
          <div className="NewMessageBox">
            <textarea
              className="MessageInput"
              style={{padding: "5px", minHeight: "80px", height: "80px", width: "100%"}}
              autoFocus
              value={messageText}
              onKeyDown={(e)=>{
                if(e.key === "Enter" && !e.shiftKey && !e.ctrlKey){handleAddMessage()}
              }}
              onChange={(e)=>setMessageText(e.target.value)}
            />
            <Button onClick={handleAddMessage} color="primary">
              Add
            </Button>
            <div>
              <input type="checkbox" checked={keepModalOpen} onChange={e=>setKeepModalOpen(e.target.checked)}/>
              <span onClick={e=>setKeepModalOpen(prev=>!prev)} style={{cursor: "pointer", fontSize: "12px"}}>Add another</span>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default MessageThread
