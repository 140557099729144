import {combineReducers} from "redux"
import List           from "./List"
import Retrieving     from "./Retrieving"

const Providers = combineReducers({
  List,
  Retrieving,
})

export default Providers
