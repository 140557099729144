import {createReducer} from "../../../../utils/Reducers"
import ActionTypes     from "../../../../model/constant/actions"

interface RetrievingInventoryByContext{
  [contextId:number] : boolean
}

const RetrievingContextInventory = createReducer<RetrievingInventoryByContext>({}, {
  [ActionTypes.RETRIEVING_CONTEXT_INVENTORY] : (state, action)=>({
    [action.payload.contextId] : action.payload.status
  }),
})

export default RetrievingContextInventory
