import {
  StandardThunk,
  createRetrieveContextInventory,
  createRetrievingContextInventory,
}                          from "../creators"
import axios, {
  AxiosError,
}                          from "axios"
import endpoint, {
  Success,
  Failure
}                          from "../../model/http/Scraping/GetInventoryWithLang"
import Context                          from "../../model/ScrapingContext"


export const getContextInventory = (context:Context):StandardThunk => (dispatch, getState) => {
  dispatch(createRetrievingContextInventory({contextId: context.ContextID, status: true}))
  axios.get<Success>(endpoint(context.AccountID, context.Lang), {
    headers : {Authorization: "Bearer "+getState().Login.User.token}
  })
  .then((response)=>{
    const contextInventory = response.data.data.inventory.filter(x=>x.state.toLowerCase() === context.VehicleState.toLowerCase()) // The endpoint we use onlyfilter the lang. We have to manually filter the state
    dispatch(createRetrieveContextInventory({contextId: context.ContextID, inventory: contextInventory}))
  })
  .catch((response:AxiosError<Failure>)=>{
    console.log(response)
  })
  .then(()=>{
    dispatch(createRetrievingContextInventory({contextId: context.ContextID, status: false}))
  })
}
