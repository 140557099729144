import {useSelector, useDispatch}   from "react-redux"
import {State}                      from "../redux/reducers/"
import {getContextsWithoutVehicles} from "../actions/Scraping/context"

const useLoadContextWithoutVehicles = () => {
  const retrieving = useSelector((state:State)=>state.Contexts.Retrieving.WithoutVehicles)
  const contextsWithoutVehicles = useSelector((state:State)=>state.Contexts.WithoutVehicles)
  const dispatch = useDispatch()
  if(!contextsWithoutVehicles && !retrieving){
    dispatch(getContextsWithoutVehicles())
  }
  return {
    contextsWithoutVehicles,
    loadingContextsWithoutVehicles : !contextsWithoutVehicles || retrieving
  }
}

export default useLoadContextWithoutVehicles
