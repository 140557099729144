import React from 'react'
import './ContactItemInputAndTextarea.scss'

interface ContactItemTextareaInterface {
  labelText: string
  inputName: string
  handleOnChange: (e:React.ChangeEvent<HTMLTextAreaElement>) => void
  inputValue?: string
}

const ContactItemTextarea = (props: ContactItemTextareaInterface) => {

  return (
    <div className="contact_container">
      <div className="contact_group contact_group_textarea_container">
        <textarea
          className="contact_group_input contact_group_textarea"
          id={props.inputName}
          placeholder=" "
          required
          value={props.inputValue}
          onChange={props.handleOnChange}
          name={props.inputName}
        />
        <label className="contact_group_label" htmlFor={props.inputName}>
          {props.labelText + ':'}
        </label>
        <div className="contact_group_line_container"></div>
      </div>
    </div>
  )
}

export default ContactItemTextarea
