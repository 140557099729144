import {
  StandardThunk,
  createRetrievingAgencies,
  createRetrieveAgencies,
}                                         from "../creators"
import baseUrl,{Success}                  from "../../model/http/Auth2/Agencies"
import axios                              from "axios"

export const getAgencies = ():StandardThunk => (dispatch, getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingAgencies(true))
  axios.get<Success>(baseUrl,{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(createRetrieveAgencies(response.data))
  })
  .catch((error)=>{
    console.log(error)
  })
  .then(()=>{
    dispatch(createRetrievingAgencies(false))
  })

}