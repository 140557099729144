import ActionTypes, {
  ActionType,
  ActionPayload,
  ActionObject,
  Action
}                     from "../model/constant/actions"
import {State}        from "../redux/reducers/"
import {ThunkAction}  from "redux-thunk"

export type StandardThunk = ThunkAction<void, State, null, Action>

const createActionCreator = <Type extends ActionType>(type:Type) =>
  (payload:ActionPayload<Type>):ActionObject<Type> => ({type, payload})

export const createLogin = createActionCreator(ActionTypes.LOGIN)
export const createLogout = createActionCreator(ActionTypes.LOGOUT)
export const createFailedLogin = createActionCreator(ActionTypes.FAILED_LOGIN)
export const createLoggingIn = createActionCreator(ActionTypes.LOGGING_IN)
export const createRetrievingContextsWithoutVehicles = createActionCreator(ActionTypes.RETRIEVING_CONTEXTS_WITHOUT_VEHICLES)
export const createRetrieveContextsWithoutVehicles = createActionCreator(ActionTypes.RETRIEVE_CONTEXTS_WITHOUT_VEHICLES)
export const createRetrievingAccounts = createActionCreator(ActionTypes.RETRIEVING_ACCOUNTS)
export const createRetrieveAccounts = createActionCreator(ActionTypes.RETRIEVE_ACCOUNTS)
export const createAddContextAnalysisJob = createActionCreator(ActionTypes.ADD_CONTEXT_ANALYSIS_JOB)
export const createPollingContextAnalysisJob = createActionCreator(ActionTypes.POLLING_CONTEXT_ANALYSIS_JOB)
export const createPutContextAnalysisJob = createActionCreator(ActionTypes.PUT_CONTEXT_ANALYSIS_JOB)
export const createAddContextScrapingJob = createActionCreator(ActionTypes.ADD_CONTEXT_SCRAPING_JOB)
export const createPollingContextScrapingJob = createActionCreator(ActionTypes.POLLING_CONTEXT_SCRAPING_JOB)
export const createPutContextScrapingJob = createActionCreator(ActionTypes.PUT_CONTEXT_SCRAPING_JOB)
export const createRetrievingProviders = createActionCreator(ActionTypes.RETRIEVING_PROVIDERS)
export const createRetrieveProviders = createActionCreator(ActionTypes.RETRIEVE_PROVIDERS)
export const createClearContextsWithoutVehicles = createActionCreator(ActionTypes.CLEAR_CONTEXTS_WITHOUT_VEHICLES)
export const createForgetContextJob = createActionCreator(ActionTypes.FORGET_CONTEXT_JOB)
export const createRetrievingContextInventory = createActionCreator(ActionTypes.RETRIEVING_CONTEXT_INVENTORY)
export const createRetrieveContextInventory = createActionCreator(ActionTypes.RETRIEVE_CONTEXT_INVENTORY)
export const createSetContextHiddenState = createActionCreator(ActionTypes.SET_CONTEXT_HIDDEN_STATE)
export const createAddContextNote = createActionCreator(ActionTypes.ADD_CONTEXT_NOTE)
export const createRemoveContextNote = createActionCreator(ActionTypes.REMOVE_CONTEXT_NOTE)
export const createRetrievingAgencies = createActionCreator(ActionTypes.RETRIEVING_AGENCIES)
export const createRetrieveAgencies = createActionCreator(ActionTypes.RETRIEVE_AGENCIES)
