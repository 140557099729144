export default "https://auth-dealerbreacher.com/auth/index.php"

export interface BaseResponse {
  metadata: {
    start: number
    end: number
    duration: number
    peak_memory_usage: number
    average_time_spent_waiting_on_database: number
    time_spent_waiting_on_database: number
  }
}
