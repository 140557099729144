import React, { useState, useEffect } from 'react'
import vanilla from '../../media/vanilla'
import Dialog from '@material-ui/core/Dialog';
import './Modal.scss'

interface ModalInterface {
  modalData: Array<{id: string, name: string}>
  onClose: () => void
  modalOpen: boolean
  handleChangeAgencyClickListener: (agencyId: string, agencyName: string) => void
}

const Modal = (props: ModalInterface) => {
  const [fadeAway, setFadeAwayClass] = useState<string>('')
  const [canClose, setCanClose] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setCanClose(true)
    }, 800);
  }, [])

  const fader = () => {
    if(canClose === true) {
      setCanClose(false)
      setFadeAwayClass('fadeaway')
      setTimeout(() => {
        props.onClose()
      }, 800)
    }
  }

  return (
    <Dialog className={`customModalOverride ${fadeAway || ''}`} onClose={fader} open={props.modalOpen}>
      <div className={`modal`}>
        <div className={`modal_container`}>
          <div className="modal_top">
            <div className="modal_circle modal_circle_red"></div>
            <div className="modal_circle modal_circle_yellow"></div>
            <div className="modal_circle modal_circle_green"></div>
          </div>
          <div className="modal_close" onClick={fader}>
            {vanilla.a4Close()}
          </div>

          <div className="modal_body">
            {props.modalData && props.modalData.map((element) => (
            <p className="modal_text"
              key={element.id}
              onClick={() => {
                props.handleChangeAgencyClickListener(element.id, element.name)
                fader()
              }}>
              <span className="modal_title">{element.name}:</span> {element.id}
            </p>
            ))}
          </div>

        </div>
      </div>
    </Dialog>
  )
}

export default Modal